<script>
  import { onMount } from "svelte"
  import { Confetti } from "svelte-confetti"
  import { Loader } from "lucide-svelte"
  import { fade } from "svelte/transition"
  import ViralShareDiagram from "$lib/components/Marketing/ViralShareDiagram.svelte"
  import IntegrationsCloud from "$lib/components/Marketing/IntegrationsCloud.svelte"
  import Testimonial from "$lib/components/Marketing/Testimonial.svelte"

  let showThankYou = false
  let email = ""
  let isLoading = false
  let errorMessage = ""

  async function handleSubmit(event) {
    event.preventDefault()
    isLoading = true
    errorMessage = ""

    // List of common free email providers
    const freeEmailProviders = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
      "icloud.com",
      "protonmail.com",
      "mail.com",
      "zoho.com",
      "yandex.com",
    ]

    const domain = email.split("@")[1]?.toLowerCase()

    if (freeEmailProviders.includes(domain)) {
      errorMessage = "Please use your business email address"
      isLoading = false
      return
    }

    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })

      if (response.ok) {
        showThankYou = true
        email = ""
      } else {
        errorMessage = "Subscription failed. Please try again."
      }
    } catch (error) {
      console.error("Error submitting form:", error)
      errorMessage = "An error occurred. Please try again."
    } finally {
      isLoading = false
    }
  }

  onMount(() => {
    const script = document.createElement("script")
    // script.src = "http://localhost:5173/embed.js"
    script.src = "https://www.promoamp.com/embed.js"
    script.async = true
    document.body.appendChild(script)
  })
</script>

<svelte:head>
  <title>Promo Amp - Supercharge Your Business with Engaging Giveaways</title>
  <meta
    name="description"
    content="Promo Amp helps businesses create and manage engaging giveaways to boost customer engagement, increase brand awareness, and drive growth."
  />
  <link rel="canonical" href="https://www.promoamp.com" />
</svelte:head>

<main>
  <section class="relative">
    <div
      class="absolute left-1/2 -translate-x-1/2 -top-32 ml-[580px] pointer-events-none"
      aria-hidden="true"
    >
      <div
        class="w-80 h-80 rounded-full bg-gradient-to-tr from-blue-500 opacity-50 blur-[160px] will-change-[filter]"
      ></div>
    </div>
    <div
      class="absolute left-1/2 -translate-x-1/2 top-[420px] ml-[380px] pointer-events-none"
      aria-hidden="true"
    >
      <div
        class="w-80 h-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-50 blur-[160px] will-change-[filter]"
      ></div>
    </div>
    <div
      class="absolute left-1/2 -translate-x-1/2 top-[640px] -ml-[300px] pointer-events-none"
      aria-hidden="true"
    >
      <div
        class="w-80 h-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-50 blur-[160px] will-change-[filter]"
      ></div>
    </div>

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-28 pb-12 md:pt-40">
        <div class="text-center">
          <div class="mb-6">
            <h1
              class="text-[44px] leading-[44px] md:text-6xl font-bold mb-6 tracking-tight"
            >
              Supercharge your business <br class="max-lg:hidden" />with viral
              giveaways
            </h1>
            <div class="max-w-3xl mx-auto">
              <p class="text-xl text-gray-600 mb-8" id="request">
                Capture your audience's attention, gain valuable leads, <br
                  class="hidden md:block"
                /> and turn them into loyal customers.
              </p>
              <div class="relative">
                <div class="text-sm text-blue-600 mb-2">
                  We are currently in private beta.
                </div>
                <div
                  class="relative max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                >
                  {#if showThankYou}
                    <div transition:fade class="text-center">
                      <h3 class="text-2xl font-bold mb-2">
                        Thank you for requesting an invite!
                      </h3>
                      <p>Keep an eye on your inbox for your invite.</p>
                      <Confetti particleCount={100} />
                    </div>
                  {:else}
                    <form
                      on:submit={handleSubmit}
                      class="flex flex-col sm:flex-row w-full sm:w-auto"
                    >
                      <div class="flex flex-col w-full sm:w-64 mr-2">
                        <input
                          type="email"
                          bind:value={email}
                          placeholder="Enter your business email"
                          class="w-full h-[50px] px-4 py-2 sm:mb-0 sm:mr-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          required
                          disabled={isLoading}
                        />
                        {#if errorMessage}
                          <span
                            class="text-red-500 text-sm mt-1 absolute bottom-[-20px]"
                            >{errorMessage}</span
                          >
                        {/if}
                      </div>
                      <button
                        type="submit"
                        class="btn text-white bg-[#4066FF] hover:bg-[#3557ee] rounded px-8 py-3 shadow-lg shadow-blue-500/25 w-full mb-4 sm:w-auto sm:mb-0 group transition-all duration-200 mt-2 md:mt-0"
                        disabled={isLoading}
                      >
                        <span class="relative inline-flex items-center">
                          {#if isLoading}
                            <Loader class="w-4 h-4 mr-2 animate-spin" />
                            Joining...
                          {:else}
                            Request an invite
                            <svg
                              class="w-4 h-4 ml-2 -mr-1 transition-transform group-hover:translate-x-0.5"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          {/if}
                        </span>
                      </button>
                    </form>
                  {/if}
                </div>
              </div>
            </div>
          </div>
          <!--trusted companies-->
          <!-- <div class="mt-20 mb-8">
            <h3 class="text-lg text-gray-600 mb-8 font-bold">
              Trusted by design teams at
            </h3>
            <div class="flex flex-wrap justify-center gap-x-12 gap-y-6">
              <img
                src="/logo.svg"
                alt="Uber"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
              <img
                src="/logo.svg"
                alt="Headspace"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
              <img
                src="/logo.svg"
                alt="Meta"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
              <img
                src="/logo.svg"
                alt="Airbnb"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
              <img
                src="/logo.svg"
                alt="Ramp"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
              <img
                src="/logo.svg"
                alt="Metalab"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
              <img
                src="/logo.svg"
                alt="Pinterest"
                class="h-7 opacity-50 hover:opacity-100 transition-opacity"
              />
            </div>
          </div> -->
          <h2 class="text-2xl font-bold mb-2 mt-20">
            Check out this <br class="lg:hidden" /><u>real</u> example giveaway
          </h2>
          <p class="text-base text-gray-700 mb-2 mb:mb-0">
            (Make sure you enter for your chance to win!)
          </p>
          <div class="max-w-3xl mx-auto">
            <a
              id="promoamp-widget"
              href="https://www.promoamp.com/c/escape-experience-competition"
              >Win an Escape Room Experience (100 winners)!</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="relative before:absolute before:inset-0 before:h-[120%] before:pointer-events-none before:bg-gradient-to-b before:from-gray-100 before:-z-10"
  >
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">
        <div class="max-w-3xl mx-auto text-center pb-4 md:pb-8">
          <h2 class="text-3xl md:text-4xl font-bold mb-4 tracking-tight">
            Over 100 ways for your audience to enter
          </h2>
          <p class="text-lg md:text-xl text-gray-700">
            Customise competition entries and controls to drive the results that
            matter most to your business.
          </p>
        </div>

        <div class="pb-4 md:pb-8">
          <div class="text-center">
            <div
              class="inline-flex relative rounded-full before:absolute before:inset-0 before:-z-10 before:scale-[.85] before:bg-gradient-to-b before:from-blue-900 before:to-sky-700/50 before:blur-3xl before:animate-[pulse_4s_cubic-bezier(.4,0,.6,1)_infinite] after:absolute after:inset-0 after:rounded-[inherit] after:[background:radial-gradient(closest-side,theme(colors.blue.500),transparent)]"
            >
              <IntegrationsCloud />
            </div>
          </div>
        </div>

        <div
          class="grid lg:grid-cols-3 overflow-hidden border-y [border-image:linear-gradient(to_right,transparent,theme(colors.slate.200),transparent)1] [&>*]:p-6 md:[&>*]:px-10 md:[&>*]:py-12 [&>*]:relative [&>*]:before:absolute [&>*]:before:bg-gradient-to-b [&>*]:before:from-transparent [&>*]:before:via-gray-200 [&>*]:before:[block-size:100%] [&>*]:before:[inline-size:1px] [&>*]:before:[inset-inline-start:-1px] [&>*]:before:[inset-block-start:0]"
        >
          <article>
            <h3 class="font-medium text-lg flex items-center space-x-2 mb-2">
              <svg
                class="fill-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
              >
                <path
                  d="M2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Zm2-4a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm1 10a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Z"
                />
              </svg>
              <span>Customised for Your Goals</span>
            </h3>
            <p class="text-base text-gray-700">
              Tailor entries to grow your mailing list, social media, or app
              downloads.
            </p>
          </article>
          <article>
            <h3 class="font-medium text-lg flex items-center space-x-2 mb-2">
              <svg
                class="fill-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
              >
                <path
                  d="M14.29 2.614a1 1 0 0 0-1.58-1.228L6.407 9.492l-3.199-3.2a1 1 0 1 0-1.414 1.415l4 4a1 1 0 0 0 1.496-.093l7-9ZM1 14a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H1Z"
                />
              </svg>
              <span>Flexible Entry Options</span>
            </h3>
            <p class="text-base text-gray-700">
              Choose from 100+ entry methods to maximise participation.
            </p>
          </article>
          <article>
            <h3 class="font-medium text-lg flex items-center space-x-2 mb-2">
              <svg
                class="fill-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
              >
                <path
                  d="M2.248 6.285a1 1 0 0 1-1.916-.57A8.014 8.014 0 0 1 5.715.332a1 1 0 0 1 .57 1.916 6.014 6.014 0 0 0-4.037 4.037Z"
                  opacity=".3"
                />
                <path
                  d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1.715-6.752a1 1 0 0 1 .57-1.916 8.014 8.014 0 0 1 5.383 5.383 1 1 0 1 1-1.916.57 6.014 6.014 0 0 0-4.037-4.037Zm4.037 7.467a1 1 0 1 1 1.916.57 8.014 8.014 0 0 1-5.383 5.383 1 1 0 1 1-.57-1.916 6.014 6.014 0 0 0 4.037-4.037Zm-7.467 4.037a1 1 0 1 1-.57 1.916 8.014 8.014 0 0 1-5.383-5.383 1 1 0 1 1 1.916-.57 6.014 6.014 0 0 0 4.037 4.037Z"
                />
              </svg>
              <span>Advanced Entry Controls</span>
            </h3>
            <p class="text-base text-gray-700">
              Lock actions or allow daily re-entries to boost engagement.
            </p>
          </article>
        </div>
      </div>
    </div>
  </section>

  <section
    class="relative before:absolute before:inset-0 before:bg-gray-900 before:-z-20"
  >
    <div
      class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
    >
      <div
        class="w-80 h-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-40 blur-[160px] will-change-[filter]"
      ></div>
    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">
        <div class="max-w-3xl mx-auto text-center pb-4">
          <h2
            class="text-3xl md:text-4xl font-bold mb-4 text-white tracking-tight"
          >
            Unlock the Power of Viral Competitions
          </h2>
          <p class="text-base md:text-xl text-gray-300">
            Reward participants for referring friends to your competition. The
            more referrals, the more entries they earn, boosting their chances
            to win big—like a tech gadget, exclusive experience, or dream
            vacation!
          </p>
        </div>

        <div
          class="group relative w-full max-w-[500px] mx-auto flex justify-center mb-8"
        >
          <div class="absolute bottom-0 -z-10">
            <div
              class="w-80 h-80 rounded-full bg-blue-500 opacity-70 blur-[160px] will-change-[filter]"
            ></div>
          </div>

          <ViralShareDiagram />
        </div>

        <div
          class="grid lg:grid-cols-3 overflow-hidden border-y [border-image:linear-gradient(to_right,transparent,theme(colors.slate.200),transparent)1] [&>*]:p-6 md:[&>*]:px-10 md:[&>*]:py-12 [&>*]:relative [&>*]:before:absolute [&>*]:before:bg-gradient-to-b [&>*]:before:from-transparent [&>*]:before:via-gray-200 [&>*]:before:[block-size:100%] [&>*]:before:[inline-size:1px] [&>*]:before:[inset-inline-start:-1px] [&>*]:before:[inset-block-start:0]"
        >
          <article>
            <h3 class="font-medium text-lg flex items-center space-x-2 mb-2">
              <svg
                class="fill-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
              >
                <path
                  d="m15.447 6.605-.673-.336a6.973 6.973 0 0 0-.761-1.834l.238-.715a.999.999 0 0 0-.242-1.023l-.707-.707a.995.995 0 0 0-1.023-.242l-.715.238a6.96 6.96 0 0 0-1.834-.761L9.394.552A1 1 0 0 0 8.5-.001h-1c-.379 0-.725.214-.895.553l-.336.673a6.973 6.973 0 0 0-1.834.761l-.715-.238a.997.997 0 0 0-1.023.242l-.707.707a1.001 1.001 0 0 0-.242 1.023l.238.715a6.959 6.959 0 0 0-.761 1.834l-.673.336a1 1 0 0 0-.553.895v1c0 .379.214.725.553.895l.673.336c.167.653.425 1.268.761 1.834l-.238.715a.999.999 0 0 0 .242 1.023l.707.707a.997.997 0 0 0 1.023.242l.715-.238a6.959 6.959 0 0 0 1.834.761l.336.673a1 1 0 0 0 .895.553h1c.379 0 .725-.214.895-.553l.336-.673a6.973 6.973 0 0 0 1.834-.761l.715.238a1.001 1.001 0 0 0 1.023-.242l.707-.707c.268-.268.361-.664.242-1.023l-.238-.715a6.959 6.959 0 0 0 .761-1.834l.673-.336A1 1 0 0 0 16 8.5v-1c0-.379-.214-.725-.553-.895ZM8 13a5 5 0 1 1 .001-10.001 5 5 0 0 1 0 10.001Z"
                />
              </svg>
              <span class="text-white">Reach New Audiences</span>
            </h3>
            <p class="text-base text-gray-300">
              Viral competitions tap into participants' networks, helping you
              quickly expand your reach to new, untapped audiences without
              additional ad spend.
            </p>
          </article>
          <article>
            <h3 class="font-medium text-xl flex items-center space-x-2 mb-2">
              <svg
                class="fill-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="12"
              >
                <path
                  d="M2 0a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2H2Zm0 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7Zm1-3a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3H3Z"
                />
              </svg>
              <span class="text-white">Grow with Word-of-Mouth</span>
            </h3>
            <p class="text-base text-gray-300">
              Encouraging referrals turns customers into advocates, driving
              organic growth as participants share your competition with their
              friends.
            </p>
          </article>
          <article>
            <h3 class="font-medium text-xl flex items-center space-x-2 mb-2">
              <svg
                class="fill-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
              >
                <path
                  d="M14.75 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Zm0 13.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM2.5 14.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM1.25 2.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM4 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-6a6 6 0 1 0 0 12A6 6 0 0 0 8 2Z"
                />
              </svg>
              <span class="text-white">Maximize Engagement</span>
            </h3>
            <p class="text-base text-gray-300">
              Fun, shareable competitions keep users involved and excited,
              boosting interaction with your brand and increasing loyalty.
            </p>
          </article>
        </div>
      </div>
    </div>
  </section>

  <!-- <Testimonial /> -->

  <section class="mt-20 mb-10">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div
        class="rounded-2xl text-center relative overflow-hidden shadow-xl before:absolute before:inset-0 before:rounded-2xl before:bg-gray-900 before:pointer-events-none before:-z-10"
      >
        <div
          class="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 -z-10"
          aria-hidden="true"
        >
          <div
            class="w-[480px] h-56 rounded-full border-[20px] border-blue-500 blur-3xl will-change-[filter]"
          ></div>
        </div>
        <div
          class="absolute left-1/2 transform -translate-x-1/2 top-0 pointer-events-none -z-10"
          aria-hidden="true"
        >
          <img
            class="max-w-none"
            src="/images/stripes-dark.svg"
            width="768"
            height="432"
            alt="Stripes"
          />
        </div>
        <div class="py-12 md:py-20 px-4 md:px-12">
          <h2
            class="tracking-tight text-3xl md:text-4xl text-gray-200 font-bold mb-6 md:mb-12 border-y [border-image:linear-gradient(to_right,transparent,theme(colors.slate.700/.7),transparent)1]"
          >
            Grow your business with viral competitions
          </h2>
          <div
            class="relative before:absolute before:inset-0 before:[border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1]"
          >
            <div
              class="relative max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
            >
              {#if showThankYou}
                <div transition:fade class="text-center text-white">
                  <h3 class="text-2xl font-bold mb-2">
                    Thank you for requesting an invite!
                  </h3>
                  <p>Keep an eye on your inbox for your invite.</p>
                  <Confetti particleCount={100} />
                </div>
              {:else}
                <form
                  on:submit={handleSubmit}
                  class="flex flex-col sm:flex-row w-full sm:w-auto"
                >
                  <div class="flex flex-col w-full sm:w-64 mr-2">
                    <input
                      type="email"
                      bind:value={email}
                      placeholder="Enter your business email"
                      class="w-full h-[50px] px-4 py-2 sm:mb-0 sm:mr-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                      disabled={isLoading}
                    />
                    {#if errorMessage}
                      <span class="text-red-500 text-sm mt-1"
                        >{errorMessage}</span
                      >
                    {/if}
                  </div>
                  <button
                    type="submit"
                    class="btn text-white bg-[#4066FF] hover:bg-[#3557ee] rounded px-8 py-3 shadow-lg shadow-blue-500/25 w-full mb-4 sm:w-auto sm:mb-0 group transition-all duration-200 mt-2 md:mt-0"
                    disabled={isLoading}
                  >
                    <span class="relative inline-flex items-center">
                      {#if isLoading}
                        <Loader class="w-4 h-4 mr-2 animate-spin" />
                        Joining...
                      {:else}
                        Request an invite
                        <svg
                          class="w-4 h-4 ml-2 -mr-1 transition-transform group-hover:translate-x-0.5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      {/if}
                    </span>
                  </button>
                </form>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
